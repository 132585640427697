import React from "react"
import Header from "../components/header"
import Layout from "../components/layout"

const contact = () => {
  return <Layout>
    <Header headerText="联系我" />
    <p>Telegram@codygua</p>
    <p>Twitter@cody2333</p>
    <p>Mail:cody23333@gmail.com</p>
  </Layout>
}

export default contact;